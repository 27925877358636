<template>
	<div class="available-fields-container row">
		<div v-for="(group, groupIndex) in availableElements" class="col-4" :key="groupIndex">
			<p class="text-uppercase text-neutral-400">{{ group.name }}</p>

			<div
				v-for="(el, elindex) in group.elements.filter(el => el.enabled)"
				:key="elindex"
				class="form-element mt-2"
				:class="{ active: el.name === element.type }"
				@click="addChosenElementObject(el.name)"
			>
				<div class="icon">
					<font-awesome-icon :icon="['fas', el.icon]" class="fa-fw" />
				</div>
				<div class="description">
					{{ el.label }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ElementsList',
	data() {
		return {
			element: {
				data: {},
			},
			availableElements: [],
		}
	},
	props: ['enabledElements'],
	created() {
		this.availableElements = [
			{
				name: 'General fields',
				elements: [
					{
						name: 'TextInputElement',
						label: 'Text field',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('TextInputElement'),
						icon: 'pen',
					},
					{
						name: 'TextareaInputElement',
						label: 'Large text field',
						enabled:
							this.enabledElements.includes('all') ||
							this.enabledElements.includes('TextareaInputElement'),
						icon: 'vector-square',
					},
					{
						name: 'NumberInputElement',
						label: 'Number field',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('NumberInputElement'),
						icon: 'hashtag',
					},
					{
						name: 'RadioButtonElement',
						label: 'Single choice',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('RadioButtonElement'),
						icon: 'dot-circle',
					},
					{
						name: 'CheckboxElement',
						label: 'Multiple choice',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('CheckboxElement'),
						icon: 'check-circle',
					},
					{
						name: 'SelectListElement',
						label: 'Dropdown',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('SelectListElement'),
						icon: 'caret-down',
					},
					{
						name: 'TextElement',
						label: 'Text Editor',
						enabled: this.enabledElements.includes('all') || this.enabledElements.includes('TextElement'),
						icon: 'paragraph',
					},
				],
			},
			{
				name: 'Preformated fields',
				elements: [
					{
						name: 'NameInputElement',
						label: 'Name Input',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('NameInputElement'),
						icon: 'address-card',
					},
					{
						name: 'EmailInputElement',
						label: 'Email Input',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('EmailInputElement'),
						icon: 'at',
					},
					{
						name: 'AddressInputElement',
						label: 'Address Input',
						enabled:
							this.enabledElements.includes('all') ||
							this.enabledElements.includes('AddressInputElement'),
						icon: 'map-pin',
					},
					{
						name: 'PhoneInputElement',
						label: 'Phone Input',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('PhoneInputElement'),
						icon: 'phone-alt',
					},
					{
						name: 'DatePickerElement',
						label: 'Date',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('DatePickerElement'),
						icon: 'calendar-days',
					},
					{
						name: 'TimeElement',
						label: 'Time',
						enabled: this.enabledElements.includes('all') || this.enabledElements.includes('TimeElement'),
						icon: 'clock',
					},
					{
						name: 'YearElement',
						label: 'Year',
						enabled: this.enabledElements.includes('all') || this.enabledElements.includes('YearElement'),
						icon: 'calendar-check',
					},
				],
			},
			{
				name: 'Special use fields',
				elements: [
					{
						name: 'SignatureElement',
						label: 'Signature',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('SignatureElement'),
						icon: 'signature',
					},
					{
						name: 'PaymentElement',
						label: 'Payment',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('PaymentElement'),
						icon: 'wallet',
					},
					{
						name: 'FileUploadElement',
						label: 'File Upload',
						enabled:
							this.enabledElements.includes('all') || this.enabledElements.includes('FileUploadElement'),
						icon: 'cloud-upload-alt',
					},
					{
						name: 'TableElement',
						label: 'Table',
						enabled: this.enabledElements.includes('all') || this.enabledElements.includes('TableElement'),
						icon: 'table',
					},
				],
			},
		]
	},
	methods: {
		addElement: function() {
			this.$emit('addElement', this.element)
		},
		// This function adding chosen element object to form step fields array
		addChosenElementObject(type) {
			if (type === 'TextInputElement') {
				this.element = {
					type: 'TextInputElement',
					name: 'Text',
					label: 'Text',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'TextareaInputElement') {
				this.element = {
					type: 'TextareaInputElement',
					name: 'Textarea',
					label: 'Textarea',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'NumberInputElement') {
				this.element = {
					type: 'NumberInputElement',
					name: 'Number',
					label: 'Number',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						min: null,
						max: null,
						standardNumberInput: true,
						useDynamicValue: false,
						valueCalculation: '',
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'SelectListElement') {
				this.element = {
					type: 'SelectListElement',
					name: 'Select',
					label: 'SelectList',
					placeholder: '',
					options: [
						{
							optionLabel: 'Option1',
							optionValue: 'option1',
							selected: false,
							editValue: true,
							removed: false,
						},
					],
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						multiple: false,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'RadioButtonElement') {
				this.element = {
					type: 'RadioButtonElement',
					name: 'Radio',
					label: 'RadioButton',
					placeholder: '',
					options: [
						{
							optionLabel: 'Yes',
							optionValue: 'yes',
							selected: false,
							editValue: true,
							removed: false,
						},
						{
							optionLabel: 'No',
							optionValue: 'no',
							selected: false,
							editValue: true,
							removed: false,
						},
					],
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						check_radio_inline: true,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'CheckboxElement') {
				this.element = {
					type: 'CheckboxElement',
					name: 'Checkbox',
					label: 'Checkbox',
					placeholder: '',
					options: [
						{
							optionLabel: 'Option1',
							optionValue: 'option1',
							selected: false,
							editValue: true,
							removed: false,
							addAdditionalValue: false,
							additionalValue: '',
						},
					],
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						check_radio_inline: true,
					},
					order_number: 0,
					value: [],
					removed: false,
				}
			} else if (type === 'DatePickerElement') {
				this.element = {
					type: 'DatePickerElement',
					name: 'DatePicker',
					label: 'Date',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						minDate: '1900-01-01',
						maxDate: '',
						useDynamicMinDate: true,
						useDynamicMaxDate: true,
						maxDateDynamic: '',
						minDateDynamic: '',
						prefillWithAccount: false,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'FileUploadElement') {
				this.element = {
					type: 'FileUploadElement',
					name: 'FileUpload',
					label: 'FileUpload',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						accept_type: '',
						multiple: true,
					},
					order_number: 0,
					removed: false,
					value: [],
				}
			} else if (type === 'EmailInputElement') {
				this.element = {
					type: 'EmailInputElement',
					name: 'Email',
					label: 'Email',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						prefillWithAccount: true,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'PhoneInputElement') {
				this.element = {
					type: 'PhoneInputElement',
					name: 'Phone',
					label: 'Phone',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						prefillWithAccount: true,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'TextElement') {
				this.element = {
					type: 'TextElement',
					name: 'Text',
					label: 'Text',
					value: '',
					placeholder: '',
					help: '',
					validation: '',
					required: false,
					redacted: false,
					display: 'both',
					col: 12,
					data: {},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'TableElement') {
				this.element = {
					type: 'TableElement',
					name: 'Table',
					label: 'Table',
					value: '',
					placeholder: '',
					help: '',
					validation: '',
					required: false,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						tableColumns: [
							{
								prop: 'text',
								label: 'Text',
								inputType: 'text',
								valueType: 'default',
								predefinedValues: '',
								width: '',
								editProp: true,
								removed: false,
								joinWith: 'single',
							},
						],
						tableData: [],
						addButtonText: 'Add',
						maxLines: 10,
						emptyColumnsPdf: 2,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'YearElement') {
				this.element = {
					type: 'YearElement',
					name: 'Year',
					label: 'Year',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						minYear: 1900,
						maxYear: '',
						useDynamicMinYear: false,
						useDynamicMaxYear: false,
						maxYearDynamic: '',
						minYearDynamic: '',
						minToMax: false,
					},
					order_number: 0,
					value: '',
					removed: false,
				}
			} else if (type === 'SignatureElement') {
				this.element = {
					type: 'SignatureElement',
					name: 'Signature',
					label: 'Signature',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						prefillWithAccount: true,
					},
					order_number: 0,
					value: '',
					removed: false,
				}
			} else if (type === 'PaymentElement') {
				this.element = {
					type: 'PaymentElement',
					name: 'Payment',
					label: 'Payment',
					placeholder: '',
					help: '',
					validation: '',
					required: false,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						quantity: 1,
						price: 0,
						currency: 'usd',
						paymentDepartment: '',
						paymentType: [],
						paymentFlow: 'automatic',
					},
					order_number: 0,
					value: '',
					removed: false,
				}
			} else if (type === 'AddressInputElement') {
				this.element = {
					type: 'AddressInputElement',
					name: 'Address',
					label: 'Address',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						prefillWithAccount: true,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'NameInputElement') {
				this.element = {
					type: 'NameInputElement',
					name: 'Name',
					label: 'Name',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {
						firstName: true,
						middleName: false,
						lastName: true,
						firstNameLabel: 'First name',
						middleNameLabel: 'Middle name',
						lastNameLabel: 'Last name',
						prefillWithAccount: true,
					},
					order_number: 0,
					removed: false,
				}
			} else if (type === 'TimeElement') {
				this.element = {
					type: 'TimeElement',
					name: 'Time',
					label: 'Time',
					placeholder: '',
					help: '',
					validation: '',
					required: true,
					redacted: false,
					display: 'both',
					col: 12,
					data: {},
					order_number: 0,
					removed: false,
				}
			}

			this.addElement()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables';

.available-fields-container {
	.form-element {
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 30px;
		padding: 8px;

		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 36px;
			margin-right: 1rem;
			color: $primary-400;
			font-size: 1.2rem;
		}
		.description {
			font-size: 1rem;
			color: black;
		}
		&:hover {
			background-color: $neutral-50;
		}
		&.active {
			background-color: $primary-50;
		}
	}
}
</style>
