<template>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Move agenda item to another meeting</h5>
			<button type="button" class="btn-close" @click="$emit('close')"></button>
		</div>
		<div class="modal-body">
			<div v-if="loading" class="text-center py-4">
				<span class="spinner-border spinner-border-sm"></span> Loading meetings...
			</div>
			<div v-else-if="!meetings.length" class="text-center py-4">
				<p class="mb-0">No upcoming meetings found. Create a new meeting first.</p>
			</div>
			<div v-else>
				<div class="list-group">
					<label v-for="meeting in meetings" :key="meeting.id" class="list-group-item list-group-item-action">
						<div class="d-flex align-items-center">
							<input
								type="radio"
								name="meeting"
								:value="meeting.id"
								v-model="selectedMeetingId"
								class="me-3"
							/>
							<div>
								<div class="fw-bold">{{ meeting.title }}</div>
								<small class="text-muted">
									{{ meeting.starts_at | dateLocal(undefined, { timeZone: j.timezone }) }}
									<span
										v-if="meeting.status !== 'public'"
										class="badge bg-warning-50 text-warning ms-2"
									>
										{{ meeting.status }}
									</span>
								</small>
							</div>
						</div>
					</label>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
			<button
				type="button"
				class="btn btn-primary"
				:disabled="!selectedMeetingId || loading"
				@click="handleSubmit"
			>
				Move item
			</button>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import heyGovApi from '@/api.js'

export default {
	name: 'MoveToMeetingModal',
	props: {
		currentMeetingId: {
			type: [String, Number],
			required: true,
		},
	},
	data() {
		return {
			meetings: [],
			selectedMeetingId: null,
			loading: true,
		}
	},
	computed: {
		...mapState(['j']),
	},
	async created() {
		try {
			const { data } = await heyGovApi.get(`${this.j.slug}/meetings`, {
				params: {
					status: ['draft', 'public'],
					starts_after: new Date().toISOString(),
					order_by: 'starts_at',
				},
			})

			this.meetings = data
		} catch (error) {
			console.error('Error loading meetings:', error)
		} finally {
			this.loading = false
		}
	},
	methods: {
		handleSubmit() {
			const toMeeting = this.meetings.find(m => m.id === this.selectedMeetingId)
			this.$emit('submit', toMeeting)
		},
	},
}
</script>
