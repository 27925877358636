<template>
	<bubble-menu
		:editor="editor"
		:tippy-options="{ duration: 100, maxWidth: 1024, onHidden: closeAISuggestion }"
		v-if="editor"
	>
		<div class="bubble-menu">
			<div v-if="isAISuggestionVisible" class="suggestion">
				<div v-if="aiSuggestionText" style="width: 100%">
					<div class="suggestion-text mb-1" v-html="makeHtml(aiSuggestionText)" v-auto-scroll></div>
					<button :disabled="isStreamingInProgress" class="btn btn-md" @click="applySuggestion">
						Accept
					</button>
					<button :disabled="isStreamingInProgress" class="btn btn-md" @click="closeAISuggestion">
						Discard
					</button>
				</div>
				<div v-else class="text-center my-2">
					<span class="spinner-border spinner-border-sm" role="status"></span> thinking..
				</div>
			</div>
			<div class="d-flex align-items-center" v-else>
				<!-- AI Actions Dropdown -->
				<div class="dropdown">
					<button
						class="btn btn-md dropdown-toggle"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<font-awesome-icon :icon="['fas', 'magic-wand-sparkles']" class="text-primary-400 me-2" />
						<span class="text-ai">AI Actions</span>
					</button>
					<ul class="dropdown-menu">
						<!-- Make Longer -->
						<button
							class="dropdown-item"
							@click="handleAIActionClick('make_longer')"
							@contextmenu.prevent="$emit('make-longer-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'text-height']" style="margin-right: 10px" />
							<span>Make Longer</span>
						</button>

						<!-- Make Shorter -->
						<button
							class="dropdown-item"
							@click="handleAIActionClick('make_shorter')"
							@contextmenu.prevent="$emit('make-shorter-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'minimize']" style="margin-right: 10px" />
							<span>Make Shorter</span>
						</button>

						<!-- Describe Your Change -->
						<button
							class="dropdown-item"
							@click="handleAIActionClick('describe_your_change')"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'comment']" style="margin-right: 10px" />
							<span>Describe Your Change</span>
						</button>

						<!-- Rewrite in Style -->
						<div class="dropdown-divider"></div>
						<h6 class="dropdown-header" style="margin-bottom: 4px;">Rewrite in Different Style</h6>
						<!-- Official Gov Meeting -->
						<button
							class="dropdown-item"
							@click="
								// @ts-ignore
								handleAIActionClick('describe_your_change', 'Rewrite in style: Official Gov Meeting')
							"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'gavel']" style="margin-right: 10px" />
							<span>Official Gov Meeting</span>
						</button>
						<!-- Business Formal -->
						<button
							class="dropdown-item"
							@click="
								// @ts-ignore
								handleAIActionClick('describe_your_change', 'Rewrite in style: Business Formal')
							"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'briefcase']" style="margin-right: 10px" />
							<span>Business Formal</span>
						</button>
						<!-- Casual Language -->
						<button
							class="dropdown-item"
							@click="
								// @ts-ignore
								handleAIActionClick('describe_your_change', 'Rewrite in style: Casual Language')
							"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'smile']" style="margin-right: 10px" />
							<span>Casual Language</span>
						</button>
						<!-- Resolution Minutes -->
						<button
							v-if="jurisdictionsWithResolutionMinutes.includes(j.slug)"
							class="dropdown-item"
							@click="handleAIActionClick('describe_your_change', promptResolutionMinutes)"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'gavel']" style="margin-right: 10px" />
							Resolution Minutes <span class="badge bg-danger-50 text-danger-400">beta</span>
						</button>
					</ul>
				</div>

				<!-- Text Dropdown -->
				<div class="dropdown">
					<button
						class="btn btn-md dropdown-toggle"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<font-awesome-icon :icon="['fas', 't']" style="margin-right: 10px" />
						<span>Text</span>
					</button>
					<ul class="dropdown-menu">
						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleBlockquote()
									.run()
							"
							:disabled="
								!editor
									.can()
									.chain()
									.focus()
									.toggleBlockquote()
									.run()
							"
							:class="{ active: editor.isActive('blockquote') }"
						>
							<font-awesome-icon :icon="['fas', 'quote-right']" style="margin-right: 10px" />
							<span>Quote</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleStrike()
									.run()
							"
							:disabled="
								!editor
									.can()
									.chain()
									.focus()
									.toggleStrike()
									.run()
							"
							:class="{ active: editor.isActive('strike') }"
						>
							<font-awesome-icon
								:icon="['fas', 'strikethrough']"
								class="icon alt"
								style="margin-right: 10px"
							/>
							<span>Strikethrough</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleHeading({ level: 1 })
									.run()
							"
							:class="{ active: editor.isActive('heading', { level: 1 }) }"
						>
							<span style="margin-right: 10px;">h1</span>
							<span>Header Level 1</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleHeading({ level: 2 })
									.run()
							"
							:class="{ active: editor.isActive('heading', { level: 2 }) }"
						>
							<span style="margin-right: 10px;">h2</span>
							<span>Header Level 2</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleHeading({ level: 3 })
									.run()
							"
							:class="{ active: editor.isActive('heading', { level: 3 }) }"
						>
							<span style="margin-right: 10px;">h3</span>
							<span>Header Level 3</span>
						</button>
					</ul>
				</div>

				<!-- bold -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleBold()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleBold()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('bold') }"
				>
					<font-awesome-icon :icon="['fas', 'bold']" class="icon alt" />
				</button>

				<!-- italic -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleItalic()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleItalic()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('italic') }"
				>
					<font-awesome-icon :icon="['fas', 'italic']" class="icon alt" />
				</button>

				<!-- underline -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleUnderline()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleUnderline()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('underline') }"
				>
					<font-awesome-icon :icon="['fas', 'underline']" class="icon alt" />
				</button>

				<!-- link -->
				<button class="btn" @click="$emit('setLink')" :class="{ 'btn-primary': editor.isActive('link') }">
					<font-awesome-icon :icon="['fas', 'link']" />
				</button>
			</div>
		</div>
	</bubble-menu>
</template>

<style lang="scss" scoped>
.bubble-menu {
	padding: 5px;
	background-color: rgba(250, 250, 250, 0.8);
	backdrop-filter: blur(10px);
	border: 1px solid #dedede;
	border-radius: 10px;

	.btn {
		padding: 5px 10px;
		border-radius: 5px;

		&:hover {
			background-color: #e2e2e2;
		}
	}
}

.suggestion {
	font-size: 0.9rem;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 500px;
}

.suggestion-text {
	width: 100%;
	background-color: white;
	max-height: 200px;
	overflow-y: scroll;
	border: 1px solid #dedede;
	border-radius: 5px;
	padding: 10px;

	& > :last-child {
		margin-bottom: 0;
	}
}
</style>

<script>
import { mapState } from 'vuex'
import { Converter } from 'showdown'
import { BubbleMenu } from '@tiptap/vue-2'

const converter = new Converter()
converter.setOption('simplifiedAutoLink', true)
converter.setOption('openLinksInNewWindow', true)

export default {
	components: { BubbleMenu },
	props: {
		editor: { type: Object },
		onSetLink: { type: Function },
		onMakeLonger: { type: Function },
	},
	data() {
		return {
			isAISuggestionVisible: false,
			aiSuggestionText: '',
			isStreamingInProgress: false,

			jurisdictionsWithResolutionMinutes: ['heyville.org', 'clerkminutes.com', 'cityofenglewood.org'],
			promptResolutionMinutes: `convert to "Resolution Minutes" style. Firstly, you can recognize when to use the style of "Resolution Minutes" when you see in transcript where there is discussion about Ordinances or Resolutions and where the phrasing of several "WHEREAS" are used and where it ends with a "THEREFORE" phrase. I've given an example below of what these "Resolution Minutes" look and sound like for a Resolution and for an Ordinance.

Sometimes there will be Public Comments for these items and also for items when the agenda item includes the words "Public Hearing" and "Consent Agenda". Make sure to note the names of each person speaking, and give a bullet point summary of their main question(s) and suggetion(s) are. Place these Public Comments immediately after the Resolution Minutes.

add address after somebody's who speaks in public (for things like public sessions or public comments)

Example styles:
- Amy Bullock, 312 Howland Ave:

- Ricardo Whilby, 287 Shepard Ave:

Sometimes with the "Resolution Minutes", it seems like there needs to be a copy of the newly adopted Ordinance or Resolution needs to be included. If you feel like there should be something more formal added and you do not know what that it is, simply add a placeholder text in ALL CAPS AND BOLD:
[INSERT COPY OF ADOPTED RESOLUTION] or [INSERT COPY OF ADOPTED ORDINANCE]

And one more thing, for the formatting and styling of 'WHEREAS" and the "THEREFORE" phrase, please format that in bold text.

Also, for Ordinances that have numbers, make sure to include the hashtag (#) before the number. Example: ORDINANCE #24-01. Please also make that text BOLD and UNDERLINED.

Below are two example to use as inspiration:
<start example resolution minutes Number 1>

RESOLUTION #385-12-19-23: APPROVE PAYMENT OF BILLS AND CLAIMS

WHEREAS, The Chief Financial Officer has certified and submitted a consolidated bill and

claims list for payment as well as a consolidated list of prepaid items. The prepaid items include

emergency payments, wire transfers and regularly scheduled monthly payments that are paid between

bill and claims list dates; and

WHEREAS, all bills and claims listed herewith have been encumbered and sufficient funds are

available for payment; and

WHEREAS, the required signatures have all been obtained on each voucher on the attached list.

City Council Meeting Minutes December 19, 2023 Page 2 of 21

NOW, THEREFORE, BE IT RESOLVED, by the Council of the City of Englewood, that the

bills and claims on the submitted lists are hereby approved for payment in the total amount of

$3,707,530.03

</end example resolution minutes Number 1>

<start example ordinance Number 1>

ORDINANCE #23-31

AN ORDINANCE OF THE CITY OF ENGLEWOOD, COUNTY OF BERGEN, STATE

OF NEW JERSEY, ESTABLISHING CHAPTER 315 "PROCEDURE TO NAME

PUBLIC SPACES" OF THE GENERAL LEGISLATION OF THE CITY OF

ENGLEWOOD

WHEREAS, the City Council of the City of Englewood has many noted individuals and

organizations worthy of honor; and

WHEREAS, it is a common practice to co-name an existing street, parks or public buildings to

honor and commemorate an individual or organization; and

WHEREAS, the City of Englewood does not have guidelines on the process of co-naming

streets, parks or buildings; and

WHEREAS, the City Council of the City of Englewood wishes to establish Chapter 315

"Procedure to Name Public Spaces" for such guidelines;

NOW THEREFORE, BE IT ORDAINED, by the City Council of the City of Englewood, in

the County of Bergen and State of New Jersey, that Chapter 315 "Procedure to Name Public Spaces" is

hereby established and made part of the Revised General Ordinances of the City of Englewood:

</end example ordinance Number 1>`,
		}
	},
	computed: {
		...mapState(['j']),
	},
	methods: {
		makeHtml(text) {
			return converter.makeHtml(text)
		},
		update(md, done) {
			this.aiSuggestionText += md
			if (done) {
				this.isStreamingInProgress = false
			}
		},
		handleAIActionClick(command, prompt = undefined) {
			if (command === 'describe_your_change' && prompt === undefined) {
				// @ts-ignore
				prompt = window.prompt('Describe your change')
				if (!prompt) {
					return
				}
			}

			const { from, to } = this.editor.view.state.selection
			const selectedText = this.editor.state.doc.textBetween(from, to, ' ')

			this.isAISuggestionVisible = true
			this.isStreamingInProgress = true
			this.aiSuggestionText = ''

			this.$emit('ai-action', {
				command: command,
				prompt: prompt,
				fullText: this.editor.getHTML(),
				selection: selectedText,
				update: this.update,
			})
		},
		closeAISuggestion() {
			this.isAISuggestionVisible = false
			this.aiSuggestionText = ''
		},
		applySuggestion() {
			const { from, to } = this.editor.view.state.selection

			const replacing = this.makeHtml(this.aiSuggestionText)

			this.editor
				.chain()
				.focus()
				.insertContentAt({ from, to }, replacing)
				.run()
		},
	},
}
</script>
