<template>
	<div class="dropdown">
		<button
			class="btn btn-sm btn-dark dropdown-toggle"
			type="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
		>
			<font-awesome-icon :icon="['fas', 'calendar-days']" class="me-1" />
			Add to calendar
		</button>
		<ul class="dropdown-menu">
			<li>
				<a class="dropdown-item" target="google-calendar" :href="googleCalendarUrl">Google Calendar</a>
			</li>
			<li>
				<a class="dropdown-item" :href="icsFileUrl"
					>iCalendar <span class="badge bg-neutral-50 text-dark">.ics</span></a
				>
			</li>
			<li>
				<a class="dropdown-item" target="outlook-calendar" :href="outlookCalendarUrl">Outlook Calendar</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { addHours, format } from 'date-fns'
import { mapState } from 'vuex'

export default {
	name: 'AddToCalendarDropdown',
	props: {
		event: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			icsFileUrl: null,
		}
	},
	computed: {
		...mapState(['j']),
		googleCalendarUrl() {
			// info about google calendar links
			// https://useroffline.blogspot.com/2009/06/making-google-calendar-link.html

			const startsAt = new Date(this.event.starts_at.slice(0, -1)) // remove Z from end
			const endsAt = this.event.ends_at ? new Date(this.event.ends_at.slice(0, -1)) : addHours(startsAt, 1)

			const url = new URL('https://www.google.com/calendar/render')
			url.searchParams.append('action', 'TEMPLATE')
			url.searchParams.append('text', this.event.name || this.event.title)
			url.searchParams.append('ctz', this.j.timezone)
			url.searchParams.append(
				'dates',
				`${format(startsAt, `yyyyMMdd'T'HHmmss'Z'`)}/${format(endsAt, `yyyyMMdd'T'HHmmss'Z'`)}`
			)
			url.searchParams.append('details', this.event.description)

			//todo add event.location
			//url.searchParams.append('location', '')

			return url.href
		},
		outlookCalendarUrl() {
			const url = new URL(
				'https://outlook.live.com/calendar/0/deeplink/compose?startdt=START_DATE&enddt=END_DATE'
			)
			url.searchParams.append('path', '/calendar/action/compose')
			url.searchParams.append('subject', this.event.name || this.event.title)
			url.searchParams.append('body', this.event.description)
			url.searchParams.append('startdt', this.event.starts_at_local || this.event.starts_at)
			url.searchParams.append('enddt', this.event.ends_at_local || this.event.ends_at)

			//todo add event.location
			//url.searchParams.append('location', '')

			return url.href
		},
	},
	created() {
		this.buildIcsFile()
	},
	methods: {
		buildIcsFile() {
			const createdAt = new Date(this.event.created_at)
			const startsAt = new Date(this.event.starts_at.slice(0, -1)) // remove Z from end
			const endsAt = this.event.ends_at ? new Date(this.event.ends_at.slice(0, -1)) : addHours(startsAt, 1)

			const data = [
				'BEGIN:VCALENDAR',
				'VERSION:2.0',
				`PRODID:-//HeyGov//${this.j.slug}`,
				'BEGIN:VEVENT',
				`UID:${this.event.pid}`,
				`DTSTAMP:${format(createdAt, `yyyyMMdd'T'HHmmss'Z'`)}`,
				`DTSTART;TZID=${this.j.timezone}:${format(startsAt, `yyyyMMdd'T'HHmmss'Z'`)}`,
				`DTEND;TZID=${this.j.timezone}:${format(endsAt, `yyyyMMdd'T'HHmmss'Z'`)}`,
				'STATUS:CONFIRMED',
				`SUMMARY:${this.event.name || this.event.title}`,
				`DESCRIPTION:${this.event.description}`,
				//'LOCATION:123 Main St, Anytown',
				'END:VEVENT',
				'END:VCALENDAR',
			]

			const icsFile = new File([data.join('\n')], `${this.j.slug}-${this.event.pid}.ics`, {
				type: 'text/calendar',
			})
			this.icsFileUrl = URL.createObjectURL(icsFile)
		},
	},
	beforeDestroy() {
		if (this.icsFileUrl) {
			URL.revokeObjectURL(this.icsFileUrl)
		}
	},
}
</script>
