<template>
	<div class="app-sidebar bg-white rounded-3 p-sm-3" :class="{ 'mini-sidebar': sidebarMini }">
		<div
			class="sidebar-overlay"
			:class="{ 'sidebar-open': menuOpen }"
			@click="$store.commit('toggleMenu', false)"
		></div>
		<nav
			aria-label="HeyGov navigation"
			:class="{
				'sidebar-open': menuOpen,
				'not-onboarded': !onboarded && !j,
			}"
			@click="$store.commit('toggleMenu')"
		>
			<router-link to="/" class="sidebar-brand text-center text-lg-start pt-0 mb-3">
				<img src="https://files.heygov.com/assets/heygov-logo.png" width="60" height="60" alt="HeyGov" />
			</router-link>

			<ul class="list-unstyled mb-0 py-3 pt-md-1" v-if="currentRole === 'CITIZEN'">
				<li class="mb-2">
					<router-link :to="`/${j ? j.slug : ''}`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'home']" class="fa-fw me-1" /> Home</router-link
					>
				</li>

				<li v-if="j" class="mb-2">
					<a :href="j.website" target="_blank" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="fa-fw me-1" />
						<span class="text-capitalize">{{ j.type }}</span> website</a
					>
				</li>

				<li v-if="j && j.features && j.features.includes('issues')" class="mb-2">
					<router-link :to="`/${j ? j.slug : '1'}/threads`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'chart-bar']" class="fa-fw me-1" /> 311 Requests</router-link
					>
				</li>

				<li v-if="j && j.features && j.features.includes('polls')" class="mb-2">
					<router-link :to="`/${j ? j.slug : '1'}/polls`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'vote-yea']" class="fa-fw me-1" /> Straw Polls</router-link
					>
				</li>

				<li v-if="j && j.features && j.features.includes('forms')" class="mb-2">
					<router-link :to="`/${j ? j.slug : '1'}/forms`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'align-center']" class="fa-fw me-1" />
						Licenses and permits
					</router-link>
				</li>

				<li v-if="j && j.features && j.features.includes('venues')" class="mb-2">
					<router-link :to="`/${j ? j.slug : '1'}/venues`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'calendar-days']" class="fa-fw me-1" /> Book a venue
					</router-link>
				</li>

				<li v-if="j && j.features && j.features.includes('payments')" class="mb-2">
					<router-link :to="`/${j.slug}/quick-pay`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'dollar-sign']" class="fa-fw me-1" />
						Payments
					</router-link>
				</li>

				<!-- 	<li v-if="j && j.features && j.features.includes('venues')" class="mb-2">
					<router-link :to="`/${j ? j.slug : '1'}/venues`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'calendar-days']" class="fa-fw me-1" /> HeyReserve
					</router-link>
				</li> -->

				<li v-if="j && j.features && j.features.includes('faqs')" class="mb-2">
					<router-link :to="`/${j ? j.slug : '1'}/faqs`" class="btn text-start px-3"
						><font-awesome-icon :icon="['fas', 'question']" class="fa-fw me-1" /> FAQs</router-link
					>
				</li>
			</ul>

			<!-- Landon Sidebar -->
			<ul
				v-else-if="j && ['WORKER', 'EDITOR', 'ADMIN'].includes(currentRole)"
				class="landon-sidebar ps-0 mb-0 py-3 pt-md-1"
				:class="sidebarMini ? 'text-center' : ''"
			>
				<span class="position-absolute border rounded-pill" style="right: -15px; top: 90px;">
					<button
						class="btn btn-icon-light bg-light text-dark p-2 d-none d-lg-block"
						@click="$store.commit('toggleSidebar')"
					>
						<font-awesome-icon
							:icon="['fas', sidebarMini ? 'chevron-right' : 'chevron-left']"
							class="fa-fw ps-1 me-1"
						/>
					</button>
				</span>

				<li class="mb-1">
					<router-link :to="`/${j ? j.slug : '1'}/`"
						><font-awesome-icon :icon="['fas', 'home']" class="fa-fw fs-6" />
						<span v-if="!sidebarMini" class="ms-2"
							><span class="text-capitalize">{{ j.type }}</span> home</span
						></router-link
					>
				</li>

				<li class="mb-1">
					<router-link :to="`/${j ? j.slug : '1'}/dashboard`"
						><font-awesome-icon :icon="['fas', 'th-large']" class="fa-fw fs-6" />
						<span v-if="!sidebarMini" class="ms-2">Dashboard</span></router-link
					>
				</li>

				<li v-if="isStaff" class="mb-1">
					<router-link :to="`/${j ? j.slug : '1'}/people`"
						><font-awesome-icon :icon="['fas', 'user-group']" class="fa-fw fs-6" />
						<span v-if="!sidebarMini" class="ms-2">CRM</span></router-link
					>
				</li>

				<!-- <li v-if="isStaff" class="mb-1">
					<router-link :to="`/${j ? j.slug : '1'}/files`"
						><font-awesome-icon :icon="['fas', 'file-alt']" class="fa-fw fs-6" />
						<span v-if="!sidebarMini" class="ms-2">Files</span></router-link
					>
				</li> -->

				<li class="mb-3">
					<a :href="j.website" target="_blank"
						><font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="fa-fw fs-6" />
						<span v-if="!sidebarMini" class="ms-2">View website ❐</span></a
					>
				</li>

				<li class="mb-2 hey-license with-icon" :class="!j.features.includes('forms') ? 'greyout' : ''">
					<router-link
						:to="j.features.includes('forms') ? `/${j ? j.slug : '1'}/forms` : '/upgrade?feature=forms'"
						:class="$route.path.includes('form') ? 'router-link-exact-active' : ''"
					>
						<img src="https://files.heygov.com/assets/icon-heylicense.png" alt="HeyLicense" height="25" />
						<span v-if="!sidebarMini" class="ms-2">HeyLicense</span>
						<font-awesome-icon v-if="!j.features.includes('forms')" :icon="['fas', 'lock']" />
					</router-link>
				</li>

				<li class="mb-2 hey-311 with-icon" :class="!j.features.includes('issues') ? 'greyout' : ''">
					<router-link
						:to="j.features.includes('issues') ? `/${j ? j.slug : '1'}/threads` : '/upgrade?feature=issues'"
						:class="$route.path.includes('thread') ? 'router-link-exact-active' : ''"
					>
						<img src="https://files.heygov.com/assets/icon-hey311.png" alt="Hey311" height="25"/><span
							v-if="!sidebarMini"
							class="ms-2"
							>Hey311</span
						>
						<font-awesome-icon v-if="!j.features.includes('issues')" :icon="['fas', 'lock']"
					/></router-link>
				</li>

				<li class="mb-2 hey-reserve with-icon" :class="!j.features.includes('venues') ? 'greyout' : ''">
					<router-link
						:to="j.features.includes('venues') ? `/${j ? j.slug : '1'}/venues` : '/upgrade?feature=venues'"
						:class="
							$route.path.includes('venues/') && !$route.path.includes('venues/group')
								? 'router-link-exact-active'
								: ''
						"
					>
						<img src="https://files.heygov.com/assets/icon-heyreserve.png" alt="HeyReserve" height="25" />
						<span v-if="!sidebarMini" class="ms-2">HeyReserve</span>
						<font-awesome-icon v-if="!j.features.includes('venues')" :icon="['fas', 'lock']" />
					</router-link>
				</li>

				<template v-if="venueGroups">
					<li
						v-for="venueGroup in venueGroups"
						:key="venueGroup.id"
						class="mb-2 hey-reserve-marina with-icon"
					>
						<router-link
							:to="`/${j.slug}/venues/group/${venueGroup.slug}`"
							:class="
								$route.path.includes(`venues/group/${venueGroup.slug}`)
									? 'router-link-exact-active'
									: ''
							"
							:title="venueGroup.name"
						>
							<img
								v-if="venueGroup.slug.includes('campground')"
								src="https://edge.heygov.com/app-assets/icon-campground.png"
								alt="HeyReserve"
								height="25"
							/>
							<img
								v-else
								src="https://edge.heygov.com/app-assets/icon-marina.png"
								alt="HeyReserve"
								height="25"
							/>
							<small v-if="!sidebarMini" class="ms-1">{{ truncateWords(venueGroup.name, 2, '') }}</small>
						</router-link>
					</li>
				</template>

				<li
					v-if="j.features.includes('polls')"
					class="mb-2 hey-polls with-icon"
					:class="!j.features.includes('polls') ? 'greyout' : ''"
				>
					<router-link
						:to="j.features.includes('polls') ? `/${j ? j.slug : '1'}/polls` : '/upgrade'"
						:class="$route.path.includes('polls') ? 'router-link-exact-active' : ''"
					>
						<img src="https://files.heygov.com/assets/icon-heypolls.png" alt="HeyPolling" height="25" />
						<span v-if="!sidebarMini" class="ms-2">HeyPolling</span>
						<font-awesome-icon v-if="!j.features.includes('polls')" :icon="['fas', 'lock']" />
					</router-link>
				</li>

				<li class="mb-2 hey-pay with-icon" :class="!hasPayments ? 'greyout' : ''">
					<router-link
						:to="hasPayments ? `/${j.slug}/payments` : '/upgrade?feature=payments'"
						:class="$route.path.includes('pay') ? 'router-link-exact-active' : ''"
					>
						<img src="https://files.heygov.com/assets/icon-heygovpay.png" alt="HeyGov Pay" height="25" />
						<span v-if="!sidebarMini" class="ms-2">HeyGov Pay</span>
						<font-awesome-icon v-if="!hasPayments" :icon="['fas', 'lock']" />
					</router-link>
				</li>

				<li class="mb-2 hey-license with-icon" :class="{ greyout: !j.features.includes('clerkminutes') }">
					<router-link
						:to="
							j.features.includes('clerkminutes')
								? `/${j.slug}/meetings`
								: `/${j.slug}/meetings/clerkminutes`
						"
						:class="$route.path.includes('meetings') ? 'router-link-exact-active' : ''"
					>
						<img
							src="https://edge.heygov.com/app-assets/feature-clerkminutes.png"
							alt="ClerkMinutes"
							height="25"
						/>
						<span v-if="!sidebarMini" class="ms-2">ClerkMinutes</span>
						<font-awesome-icon v-if="!j.features.includes('clerkminutes')" :icon="['fas', 'lock']" />
					</router-link>
				</li>

				<div v-if="currentRole === 'ADMINS'">
					<li class="mb-2 rounded-1 bg-info-lighter mt-4 text-center" v-if="!sidebarMini">
						<router-link :to="`/upgrade`" class="py-3 text-decoration-none h-100 w-100">
							<span class="d-none d-lg-block d-md-block">Upgrade to use our premium features!</span>
							<img
								src="https://files.heygov.com/assets/illustration-upgrade.png"
								alt="HeyGov upgrade"
								width="195"
								height="145"
								class="mb-2 p-1
							d-none d-lg-block d-md-block image-upgrade"
							/>
							<button class="btn btn-primary">Upgrade</button>
						</router-link>
					</li>
					<li v-else class="m-0">
						<router-link :to="`/upgrade`" class="d-none d-lg-block d-md-block">
							<button class="btn btn-primary btn-sm px-2 mt-5">Upgrade</button>
						</router-link>
					</li>
				</div>
			</ul>

			<!-- End of landon sidebar-->

			<ul class="list-unstyled bottom-nav position-absolute mb-0">
				<li>
					<router-link to="/select-municipality" class="btn btn-sm btn-switch-muni text-center px-3"
						><font-awesome-icon :icon="['fas', 'sort']" class="fa-fw me-1" />
						{{ sidebarMini ? 'Muni' : 'Change municipality' }}</router-link
					>
				</li>

				<li class="mb-0 d-lg-none">
					<router-link to="/about-heygov" class="btn text-center px-3"
						><small> About HeyGov</small></router-link
					>
				</li>
			</ul>
		</nav>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.app-sidebar nav {
	z-index: 20;
	height: calc(100vh - 4rem);
	overflow-x: auto;
	padding-bottom: 30px;

	.sidebar-brand {
		padding-top: 10px;
		margin-bottom: 1.5rem;
		display: block;

		img {
			transition: width 0.1s ease-in-out, height 0.1s ease-in-out;
		}

		p {
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			margin-bottom: 0;
		}

		h3 {
			font-weight: 600;
			font-size: 20px;
			line-height: 23px;
			color: #2b2b2e;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.list-unstyled li a {
		color: var(--bs-gray);
		display: block;

		&:hover {
			background-color: #f0f0f0;
		}

		&.router-link-exact-active {
			background-color: var(--bs-primary);
			color: #fff;
		}
	}

	.landon-sidebar {
		list-style-type: none;

		li a {
			display: block;
			border-radius: 20px;
			color: #898c90;
			font-weight: bold;
			border: 1px solid transparent;
			padding: 6px 12px;

			&:hover {
				text-decoration: none;
			}
		}

		li:not(.with-icon) a.router-link-exact-active {
			background-color: #f0f0f0;
			color: #2b2523;
		}

		li:not(.with-icon) a:hover {
			background-color: #f0f0f0;
			color: #2b2523;
		}

		li.with-icon a {
			border-radius: 10px;
			color: #2b2523;
			line-height: 32px;
			padding: 12px 16px 10px 18px;

			img {
				margin-top: -5px;
			}
		}

		.hey-license a {
			background-color: #eff9ff;

			&:hover,
			&.router-link-exact-active {
				border-color: #22a6ff;
			}
		}

		.hey-311 a {
			background-color: #fff3f1;

			&:hover,
			&.router-link-exact-active {
				border-color: #ff5d46;
			}
		}

		.hey-reserve a {
			background-color: #f7f6ff;

			&:hover,
			&.router-link-exact-active {
				border-color: #7879f1;
			}
		}

		.hey-reserve-marina a {
			background-color: #f7f6ff;
			padding: 8px 16px 6px 18px !important;

			&:hover,
			&.router-link-exact-active {
				border-color: #cbd5e1;
			}
		}

		.hey-polls a {
			background-color: #fff6ee;

			&:hover,
			&.router-link-exact-active {
				border-color: #ff7a00;
			}
		}

		.hey-crm a {
			background-color: #f6f6f6;

			&:hover,
			&.router-link-exact-active {
				border-color: #000000;
			}
		}

		.hey-pay a {
			background-color: #f0fffc;
			&:hover,
			&.router-link-exact-active {
				border-color: #02cea7;
			}
		}

		.greyout {
			position: relative;

			img {
				filter: grayscale(100%);
				opacity: 0.5;
			}

			svg {
				font-size: 10px;
				position: absolute;
				right: 12px;
				top: calc(50% - 5px);
			}

			a {
				background-color: #f8f8f8;
				color: rgba(0, 0, 0, 0.3) !important;
				border-color: transparent;
			}

			a:hover,
			a.router-link-exact-active {
				color: rgba(0, 0, 0, 0.5) !important;
				border-color: transparent;
			}

			/* a {
				&.router-link-exact-active:not(.greyout) {
					border: solid 1px transparent;
				}
				&:hover:not(.greyout) {
					border: solid 1px transparent;
				}
			} */
		}
	}

	.image-upgrade {
		object-fit: cover;
		max-width: 100%;
		max-height: 100%;
	}

	.bottom-nav {
		bottom: 0;
		left: 0;
		right: 0;

		.btn-switch-muni {
			border-radius: 0 0 1.875rem 1.875rem;
			background-color: #fff;
			border-top: 1px solid #f0f0f0;
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
		}
	}
}

.app-sidebar.mini-sidebar nav {
	.landon-sidebar {
		li.with-icon a {
			padding-left: 8px;
			padding-right: 8px;
		}

		li.greyout svg {
			right: 6px;
			top: auto;
			bottom: 6px;
		}
	}
}

@media (max-width: 575.98px) {
	.col-sidebar {
		width: 0 !important;
		max-width: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
	}

	.sidebar-overlay {
		content: '';
		position: fixed;
		z-index: 115;
		transition: background-color 0.2s ease-out;
		top: 0;
		left: 0;
		//will-change: background-color backdrop-filter;

		&.sidebar-open {
			bottom: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.2);
			backdrop-filter: blur(5px);
		}
	}

	.app-sidebar {
		nav {
			position: fixed !important;
			top: 0;
			width: 75vw;
			background-color: white;
			z-index: 9999;
			padding: 1rem;
			height: 100vh;

			left: calc(-75vw);
			transition: left ease-in-out 0.2s;

			&.sidebar-open {
				left: 0;
			}
		}
	}
}

.app-ios .app-sidebar nav {
	padding-top: 2rem !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'

import { truncateWords } from '@/utils.js'

export default {
	name: 'Sidebar',
	computed: {
		...mapGetters(['auth', 'currentRole', 'isStaff']),
		...mapState(['j', 'sidebarMini', 'onboarded', 'menuOpen', 'venueGroups']),
		hasPayments() {
			return (
				this.j.features.includes('payments') ||
				this.j.features.includes('forms') ||
				this.j.features.includes('venues')
			)
		},
	},
	created() {
		if (this.j?.features?.includes('venues')) {
			this.$store.dispatch('getVenueGroups')
		}
	},
	methods: {
		truncateWords,
	},
}
</script>
