<template>
	<div class="page-meetings-single">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/meetings`">ClerkMinutes</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
			Test agenda processing
		</h3>

		<div class="row justify-content-center mb-4">
			<div class="col-6 col-xl-5">
				<div class="card border-dashed">
					<div class="card-body">
						<input type="file" class="form-control" accept="application/pdf" @change="uploadFile" />
					</div>
				</div>
			</div>
			<div class="col-6 col-xl-5">
				<div class="card">
					<div class="card-body">
						<p class="mb-1">
							Filename: <strong v-if="file">{{ file.name }}</strong>
						</p>
						<p class="mb-1">
							Size: <strong v-if="file">{{ (file.size / 1024 / 1024).toFixed(2) }}mb</strong>
						</p>
						<p class="card-text">
							Last modified:
							<strong v-if="file">{{ new Date(file.lastModified).toLocaleString('default') }}</strong>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="states.agenda === 'error'" class="alert alert-danger">
			Processing error: {{ error || 'unknown' }}
		</div>

		<div class="row">
			<div v-for="model in models" :key="model.name" class="col">
				<h3 class="text-center">{{ model.name }}</h3>

				<div class="card">
					<div class="card-body">
						<p v-if="model.status === 'loading'" class="text-center">
							<span class="spinner-border spinner-border-sm"></span>
							Processing..
						</p>
						<div v-else-if="model.status === 'error'" class="alert alert-danger">
							Processing error: {{ model.error || 'unknown' }}
						</div>
						<div v-else-if="model.status === 'ready'">
							<p class="mb-1">
								Cost: <strong>{{ model.agenda.cost | currency }}</strong>
							</p>
							<p class="mb-3">
								Duration: <strong>{{ (model.time / 1000).toFixed(1) }} seconds</strong>
							</p>

							<p class="mb-1">
								Meeting: <strong>{{ model.agenda.name }}</strong>
							</p>
							<p class="mb-1">
								Date: <strong>{{ model.agenda.date }}</strong>
							</p>
							<p class="mb-1">
								Link: <strong>{{ model.agenda.conferencing_link }}</strong>
							</p>
							<p class="mb-1">
								Location:
								<strong v-if="model.agenda.location.line1">{{ model.agenda.location }}</strong>
							</p>
							<p class="mb-3">
								Description: <span>{{ model.agenda.description }}</span>
							</p>
							<p class="mb-2">
								Agenda Items: <strong>{{ model.agenda.agenda_items.length }}</strong>
							</p>

							<ol class="ps-3">
								<li v-for="(item, index) in model.agenda.agenda_items" :key="index" class="my-1">
									{{ item.name }}
									<span v-if="item.speakers" class="badge bg-primary-50 text-primary-400"
										>🗣️ {{ item.speakers }}</span
									>
									<small v-if="item.text" class="text-neutral-500">- {{ item.text }}</small>
									<ul v-if="item.subitems">
										<li v-for="(subitem, subindex) in item.subitems" :key="subindex">
											{{ subitem }}
										</li>
									</ul>
								</li>
							</ol>

							<textarea
								v-if="model.agendaText"
								class="form-control"
								v-model="model.agenda.agenda_text"
								readonly
								rows="50"
							></textarea>
							<button v-else class="btn btn-sm btn-outline-dark" @click="model.agendaText = true">
								Show agenda contents
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

import { hgApi } from '@/api.js'

export default {
	name: 'MeetingsAgendaProcessingTester',
	data() {
		return {
			states: {
				agenda: 'waiting-file',
			},
			file: null,
			models: {
				gpt4o: {
					model: 'gpt-4o',
					name: 'GPT-4o',
					status: 'idle',
					time: 0,
					error: '',
					agenda: null,
					agendaText: false,
				},
				gpt4ovision: {
					model: 'gpt-4o',
					name: 'GPT-4o + Vision',
					status: 'idle',
					time: 0,
					error: '',
					agenda: null,
					agendaText: false,
				},
			},
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		hgApi
	},
	methods: {
		uploadFile($event) {
			this.file = $event.target.files[0]
			console.log(this.file)

			const body = new FormData()
			body.append('file', this.file)

			for (const model in this.models) {
				this.models[model].status = 'loading'
				const now = Date.now()

				const query = {
					model: this.models[model].model,
				}

				if (model.includes('vision')) {
					query.vision = 1
				}

				hgApi(`${this.j.slug}/meetings/process-meeting-agenda`, {
					query,
					body,
				})
					.then(response => {
						if (response.ok) {
							return response.json()
						} else {
							if (response.headers.get('content-type').startsWith('application/json')) {
								response.json().then(data => {
									this.models[model].error = data.message || response.statusText
								})
							}

							throw new Error(response.statusText)
						}
					})
					.then(agenda => {
						this.models[model].agenda = agenda

						this.models[model].time = Date.now() - now
						this.models[model].status = 'ready'
					})
					.catch(error => {
						console.warn(error)

						this.models[model].status = 'error'
						//this.models[model].error = error.message
					})
			}
		},
	},
}
</script>
