<template>
	<div class="modal-content">
		<form @submit.prevent="handleSubmit">
			<div class="modal-header">
				<h5 class="modal-title my-0">Send agenda for review</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>
					Send a copy of the agenda to a department and ask for review. A notification will be sent to each
					department member by email and text.
				</p>

				<div class="form-group mb-3">
					<label class="form-label">DEPARTMENT</label>
					<div v-if="selectedDepartment">
						<span>{{ selectedDepartment.name }}</span>
						<button class="btn btn-sm btn-outline-secondary ms-2" @click.prevent="clearDepartment">
							Change department
						</button>
					</div>
					<department-search
						v-else
						@select="setDepartment"
						:required="true"
					/>
				</div>

				<div class="form-group">
					<label class="form-label">COMMENT</label>
					<textarea
						class="form-control"
						v-model="comment"
						placeholder="Extra comment that will be sent along with the agenda. Ex: 'Does everything looks ok, or is something missing?'"
						rows="3"
					></textarea>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-primary" :disabled="!selectedDepartment || isLoading">
					Send
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import DepartmentSearch from '@/components/DepartmentSearch.vue'

export default {
	name: 'SendForReviewForm',
	components: {
		DepartmentSearch,
	},
	data() {
		return {
			selectedDepartment: null,
			comment: '',
			isLoading: false,
		}
	},
	methods: {
		setDepartment(department) {
			this.selectedDepartment = department
		},
		clearDepartment() {
			this.selectedDepartment = null
		},
		handleSubmit() {
			this.isLoading = true
			this.$emit(
				'submit',
				{
					department_id: this.selectedDepartment.id,
					comment: this.comment,
				},
				() => {
					this.isLoading = false
					this.selectedDepartment = null
					this.comment = ''
				}
			)
		},
	},
}
</script>
