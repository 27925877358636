<template>
	<draggable
		:value="nested"
		@input="handleInput"
		:group="{ name: 'g1' }"
		handle=".drag-handle"
		animation="200"
		tag="div"
		class="item-container"
	>
		<div v-for="(item, index) in nested" :key="item.id" class="item-group border mb-3 rounded-1">
			<font-awesome-icon :icon="['fas', 'grip-vertical']" class="drag-handle text-neutral-300" />

			<div class="item p-3 on-parent" :class="{ 'pb-0': !item.parent_id }">
				<div class="row gx-2">
					<div class="col">
						<strong v-if="parent_id === 0" class="text-primary-300 me-1"
							>{{ getItemNumber(index) }}.</strong
						>
						<strong v-else class="text-warning-300 me-1">{{ getItemNumber(index) }})</strong>

						<strong>{{ item.title }}</strong>

						<span
							v-if="item.status && item.status !== 'ok'"
							class="badge ms-2"
							:class="{
								'bg-primary-50 text-primary-400': item.status === 'needs_review',
								'bg-warning-50 text-warning-400': item.status === 'change_requested',
							}"
						>
							{{ item.status.replace('_', ' ') }}
						</span>
					</div>
					<div class="col-auto">
						<div class="dropdown show-on-hover">
							<button
								class="btn btn-sm btn-icon-primary"
								type="button"
								id="dropdownMenuButton"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<li>
									<button class="dropdown-item" @click="editItem(item)">
										<font-awesome-icon :icon="['fas', 'pencil']" class="fa-fw" /> Edit
									</button>
								</li>
								<li v-if="allowApprove && item.status === 'needs_review'">
									<button class="dropdown-item" @click="approveItem(item)">
										<font-awesome-icon :icon="['fas', 'check']" class="fa-fw" /> Approve
									</button>
								</li>
								<li>
									<button
										:disabled="!allowRequestChanges"
										class="dropdown-item"
										@click="requestChanges(item)"
									>
										<font-awesome-icon :icon="['fas', 'exclamation-circle']" class="fa-fw" />
										Request Changes
									</button>
								</li>
								<!-- "Move to queue" feature is not implemented yet, button is hidden so doesn't confuse users
								<li>
									<button class="dropdown-item" @click="alert('Not implemented yet')">
										<font-awesome-icon :icon="['fas', 'exchange-alt']" class="fa-fw" /> Move to queue
									</button>
								</li>
								-->
								<li>
									<button class="dropdown-item" @click="moveToAnotherMeeting(item)">
										<font-awesome-icon :icon="['fas', 'arrow-right']" class="fa-fw" /> Move to
										another meeting
									</button>
								</li>
								<li><hr class="dropdown-divider" /></li>
								<li>
									<button class="dropdown-item text-danger-400" @click="removeItem(item)">
										<font-awesome-icon :icon="['fas', 'trash']" class="fa-fw" /> Delete
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<p v-if="item.text" class="text-neutral-400">
					{{ item.text }}
				</p>

				<div class="row gy-2 gx-2">
					<div v-if="item.internal_note" class="col-auto">
						<span
							class="d-inline-block px-2 py-1 border rounded-1 bg-warning-50 text-dark"
							:title="item.internal_note"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
						>
							<div class="d-flex align-items-center">
								<font-awesome-icon :icon="['fas', 'note-sticky']" class="text-warning-300 me-2" />
								Note
							</div>
						</span>
					</div>

					<div v-if="item.speaker_id" class="col-auto">
						<span class="d-inline-block px-2 py-1 border rounded-1 bg-neutral-50">
							<PersonLink :id="item.speaker_id" :avatar="24" :preview="true" />
						</span>
					</div>

					<div v-for="file in item.files" :key="file" class="col-auto">
						<a
							:href="getPublicFileUrl(file)"
							target="_blank"
							class="d-inline-block px-2 py-1 border rounded-1 bg-neutral-50 text-dark"
						>
							<div class="d-flex align-items-center">
								<font-awesome-icon
									:icon="['fas', file.endsWith('pdf') ? 'file-pdf' : 'file']"
									class="text-primary-100 me-2"
								/>
								<span class="d-inline-block text-truncate" style="max-width: 150px;">
									{{ file.split('/').at(-1) }}
								</span>
							</div>
						</a>
					</div>
				</div>
			</div>

			<agenda-items-list
				v-if="parent_id === 0"
				:items="item.items"
				:parent_id="item.id"
				:settings="settings"
				:allowRequestChanges="allowRequestChanges"
				:allowApprove="allowApprove"
				@updateOrder="updateSubItemsOrder"
				@removeItem="removeItem"
				@editItem="editItem"
				@requestChanges="requestChanges"
				@approve="approveItem"
				@moveToAnotherMeeting="moveToAnotherMeeting"
				class="items-sub p-3 rounded-1"
			/>
		</div>
	</draggable>
</template>

<script>
import draggable from 'vuedraggable'
import { Tooltip } from 'bootstrap'

import { getPublicFileUrl } from '@/utils.js'
import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'agenda-items-list',
	components: {
		draggable,
		PersonLink,
	},
	props: {
		items: {
			required: true,
			type: Array,
		},
		parent_id: {
			type: Number,
			required: true,
		},
		allowApprove: {
			type: Boolean,
			required: true,
		},
		allowRequestChanges: {
			type: Boolean,
			required: true,
		},
		settings: {
			type: Object,
			default: () => ({
				main_items_numbering: 'numeric',
				sub_items_numbering: 'numeric',
			}),
		},
	},
	data() {
		return {
			nested: this.nest(this.items),
		}
	},
	mounted() {
		document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
			new Tooltip(el)
		})
	},
	watch: {
		items: {
			handler(newItems) {
				// when we update this.nested from updateSubItemsOrder method, we emit `updateOrder` from `nested` watcher,
				// which lead to passing new `:items` prop to sub-items instances and triggers this handler again,
				// if we'll update this.nested here, we'll end up with infinite loop, and to prevent that,
				// we only react on props changes at parent level. this is enough, because all instances support their own
				// internal `this.nested` local state.
				if (this.parent_id === 0) {
					this.nested = this.nest(newItems)
				}
			},
			deep: false, // it's important to only react on changes our level, for sub-items we have special event
		},
		nested: {
			handler(newNested) {
				// this function called either
				// at parents list level to propagate to MeetingAgenda component
				// or at sub-items level to propagate to parents list level
				this.$emit('updateOrder', this.flatten(newNested), this.parent_id)
			},
			deep: false,
		},
	},
	methods: {
		getPublicFileUrl,

		// each return branch will trigger watch:nested handler to emit an event
		// we could get rid of that, but we would have to duplicate emitting here in all branches
		handleInput(newNested) {
			// if item was removed rather than added, then it's nothing to handle, just update the state
			const newItem = newNested.find(newItem => !this.nested.find(oldItem => oldItem.id === newItem.id))
			if (!newItem) {
				this.nested = newNested
				return
			}

			// it's ok to have nested items at the root level
			const isRootLevel = this.parent_id === 0
			if (isRootLevel) {
				this.nested = newNested
				return
			}

			// it's ok to have flat items at the sub-items level
			const isNewItemNested = newItem.items && newItem.items.length > 0
			if (!isNewItemNested) {
				this.nested = newNested
				return
			}

			// otherwise, we have a new nested item at the sub-items level which is not allowed
			// so we need to move sub-items from the new item to the same level as the new item itself
			const subItems = newItem.items
			const newItemWithoutNested = { ...newItem, items: [] }
			this.nested = [...this.nested, newItemWithoutNested, ...subItems]
		},

		// this method is called only at parents level
		// when sub-items instance emits `updateOrder` event from `watch:nested` handler
		updateSubItemsOrder(subItems, parentId) {
			// it's important to update `this.nested` and emit `updateOrder` event from there directly,
			// instead of doing it here, because otherwise we'll end up with 2 network requests
			// in MeetingAgenda component, that are concurrent to each other
			this.nested = this.nested.map(parentItem =>
				parentItem.id === parentId ? { ...parentItem, items: subItems } : parentItem
			)
		},

		nest(items) {
			const parentItems = items
				.filter(item => item.parent_id === this.parent_id)
				.sort((a, b) => a.order - b.order)

			return parentItems.map(parentItem => ({
				...parentItem,
				items: items
					.filter(subItem => subItem.parent_id === parentItem.id)
					.sort((a, b) => a.order - b.order)
					.map(subItem => ({
						...subItem,
						items: [],
					})),
			}))
		},

		flatten(nested) {
			const flat = []

			nested.forEach((parentItem, parentIndex) => {
				flat.push({
					...parentItem,
					order: parentIndex,
					parent_id: 0,
					items: undefined,
				})
				if (parentItem.items && parentItem.items.length > 0) {
					parentItem.items.forEach((subItem, subIndex) => {
						flat.push({
							...subItem,
							order: subIndex,
							parent_id: parentItem.id,
							items: undefined,
						})
					})
				}
			})

			return flat
		},

		editItem(item) {
			this.$emit('editItem', item)
		},

		removeItem(item) {
			this.$emit('removeItem', item)
		},

		requestChanges(item) {
			this.$emit('requestChanges', item)
		},

		alert(text) {
			window.alert(text)
		},

		approveItem(item) {
			this.$emit('approve', item)
		},

		getItemNumber(index) {
			if (this.parent_id === 0) {
				return this.getMainItemNumber(index)
			}
			return this.getSubItemNumber(index)
		},

		getMainItemNumber(index) {
			const num = index + 1
			switch (this.settings.main_items_numbering) {
				case 'alphabetic':
					return String.fromCharCode(64 + num) // A, B, C...
				case 'roman':
					return this.toRoman(num) // I, II, III...
				case 'numeric':
				default:
					return num // 1, 2, 3...
			}
		},

		getSubItemNumber(index) {
			const num = index + 1
			switch (this.settings.sub_items_numbering) {
				case 'alphabetic':
					return String.fromCharCode(96 + num) // a, b, c...
				case 'roman':
					return this.toRoman(num).toLowerCase() // i, ii, iii...
				case 'numeric':
				default:
					return num
			}
		},

		toRoman(num) {
			const roman = {
				M: 1000,
				CM: 900,
				D: 500,
				CD: 400,
				C: 100,
				XC: 90,
				L: 50,
				XL: 40,
				X: 10,
				IX: 9,
				V: 5,
				IV: 4,
				I: 1,
			}
			let str = ''
			for (let i of Object.keys(roman)) {
				let q = Math.floor(num / roman[i])
				num -= q * roman[i]
				str += i.repeat(q)
			}
			return str
		},

		moveToAnotherMeeting(item) {
			this.$emit('moveToAnotherMeeting', item)
		},
	},
	emits: ['updateOrder', 'removeItem', 'editItem', 'requestChanges', 'approve', 'moveToAnotherMeeting'],
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables';

.item-container {
	margin: 0;
}

.item-group {
	position: relative;
	transition: background-color 0.1s ease-in-out;

	.drag-handle {
		position: absolute;
		cursor: grab;
		top: 15px;
		left: -20px;
		padding: 4px 3px;
		border-radius: 3px;
		opacity: 0;
		transition: opacity 0.1s ease-in-out, background-color 0.1s ease-in-out;

		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	.item {
		border-radius: 0.125rem;
	}

	&:hover {
		background-color: #f5f5f5;

		.drag-handle {
			opacity: 1;
		}
	}
}

.items-sub {
	margin-left: 1.25rem;
	border-radius: 0.125rem;
}

.items-sub .item-group {
	background-color: #f9f9f9;

	&:hover {
		background-color: #e4f0ff;
	}
}

.sortable-ghost {
	border-color: $primary-300 !important;
}

.sortable-chosen:not(.sortable-ghost) {
	border-color: #bbb !important;
}

.sortable-drag {
	border-color: yellow !important;
}
</style>
