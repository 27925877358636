<template>
	<div class="department-search">
		<input
			:id="id"
			type="search"
			class="form-control"
			:placeholder="placeholder || `Search departments in ${j.name}..`"
			v-model="q"
			@keydown="onKey"
			@focus="focused = true"
			@blur="handleBlur"
			tabindex="0"
			:required="required"
		/>
		<div
			v-if="focused && filteredDepartments.length > 0"
			class="department-search-results rounded-3 shadow position-absolute bg-white"
		>
			<div class="search-results-container">
				<table class="table table-borderless mb-0">
					<tbody>
						<tr
							v-for="(dept, index) in filteredDepartments"
							:key="`department-from-search-${dept.id}-${dept.name}`"
							@click="selectDepartment(dept)"
							class="cursor-pointer"
							:class="{ highlight: index === highlight }"
							@mouseover="mouseOver(index)"
						>
							<td class="p-3 table-column text-truncate">
								<span>{{ dept.name }}</span>
								<div v-if="dept.description" class="text-muted small">{{ dept.description }}</div>
							</td>
							<td class="p-3 table-column text-truncate">
								<department-people :department="dept" :size="24" :max="3"></department-people>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.department-search-results {
	z-index: 1000;
}

.search-results-container {
	max-height: 300px;
	overflow-y: auto;
}

table {
	.table-column {
		width: 237px;
	}

	tr.highlight {
		td {
			background-color: #ecf2ff;
		}

		&:first-child {
			td:first-child {
				border-top-left-radius: 30px !important;
			}
			td:last-child {
				border-top-right-radius: 30px !important;
			}
		}

		&:last-child {
			td:first-child {
				border-bottom-left-radius: 30px !important;
			}
			td:last-child {
				border-bottom-right-radius: 30px !important;
			}
		}
	}
}
</style>

<script>
import { mapState } from 'vuex'
import DepartmentPeople from './DepartmentPeople.vue'

export default {
	name: 'DepartmentSearch',
	components: { DepartmentPeople },
	props: {
		placeholder: String,
		required: Boolean,
		id: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			q: '',
			highlight: 0,
			focused: false,
		}
	},
	created() {
		this.$store.dispatch('loadDepartments')
	},
	computed: {
		...mapState(['j', 'departments']),
		filteredDepartments() {
			const query = this.q.trim().toLowerCase()
			return (this.departments || []).filter(
				dept => dept.status === 'active' && dept.name.toLowerCase().includes(query)
			)
		},
	},
	mounted() {
		// close dropdown when clicked outside
		window.addEventListener('click', e => {
			if (!this.$el.contains(e.target)) {
				this.q = ''
				this.focused = false
			}
		})
	},
	methods: {
		selectDepartment(department) {
			this.$emit('select', department)
			this.focused = false
		},
		handleBlur() {
			// Small delay to allow click events to fire first
			setTimeout(() => {
				this.focused = false
			}, 200)
		},
		onKey(event) {
			switch (event.keyCode) {
				case 13: // Enter
					this.departments.forEach((d, index) => {
						if (index === this.highlight) {
							this.selectDepartment(d)
						}
					})
					break
				case 38: // Up arrow
					if (this.highlight === 0) {
						this.highlight = this.departments.length - 1
					} else {
						this.highlight--
					}
					break
				case 40: // Down arrow
					if (this.highlight === this.departments.length - 1) {
						this.highlight = 0
					} else {
						this.highlight++
					}
					break
			}
		},
		mouseOver(index) {
			this.highlight = index
		},
	},
}
</script>
